import { useState } from "react";

export default function useBannerPagination(count) {
  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = Math.ceil(count / 3);

  const handleNext = () => {
    if (pageNumber < totalPages) {
      const nextPage = pageNumber + 1;
      setPageNumber(nextPage);
    }
  };

  const handlePrev = () => {
    if (pageNumber > 1) {
      const prevPage = pageNumber - 1;
      setPageNumber(prevPage);
    }
  };

  return {
    totalPages,
    pageNumber,
    handleNext,
    handlePrev,
  };
}
