import { useAppQuery } from "@/hooks";
import { useReadNotifications } from "@/hooks/useReadNotification";
import { usePusher } from "@/providers/PusherProvider";
import queryKeys from "@/utility/queryKeys";
import {
  BlockStack,
  Button,
  Icon,
  InlineStack,
  Popover,
  ResourceItem,
  ResourceList,
  Spinner,
  Text,
  Tooltip,
} from "@shopify/polaris";
import { CheckCircleIcon, CheckSmallIcon, NotificationIcon } from "@shopify/polaris-icons";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import socketEvents from "storeseo-enums/socketEvents";
import { useNotificationApi, useUtilityApi } from "../../hooks/apiHooks";
import { readAllNotifications, setInitialNotifications } from "../../store/features/Notifications";
import { formatDate, ONE_MINUTE, showNotification } from "../../utility/helpers.jsx";

export default function GlobalNotifications({}) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNotificationId, setLoadingNotificationId] = useState(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { socketId } = usePusher();

  const user = useSelector((state) => state.user);
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();
  const notificationApi = useNotificationApi();
  const utilityApi = useUtilityApi();

  const { data: allNotifications } = useAppQuery({
    queryKey: [queryKeys.NOTIFICATIONS_LIST],
    queryFn: async () => await notificationApi.fetchNotifications(),
    reactQueryOptions: {
      refetchInterval: ONE_MINUTE,
      refetchOnWindowFocus: true,
    },
  });

  useEffect(() => {
    dispatch(setInitialNotifications(allNotifications));
  }, [allNotifications]);

  const slicedNotifications = notifications?.list?.notifications?.slice(0, 5);

  const { mutate: markAsRead, isSuccess } = useReadNotifications();

  const markAllAsReadViaSocket = async () => {
    setLoading(true);
    dispatch(readAllNotifications());

    await utilityApi.triggerEvent(socketEvents.NOTIFICATION_ALL_READ, {
      room: user.shop,
      shopId: user.shopId,
      socketId,
    });
    setLoading(false);
    showNotification({ message: "Notification read" });
  };

  const redirectToNotifications = () => {
    navigate("/notifications");
    setShowDropdown(false);
  };

  const togglePopoverActive = useCallback(() => setShowDropdown((status) => !status), []);

  const handleResourceListItemClick = useCallback(
    (id) => {
      const { is_read = false } = notifications?.list?.notifications?.find((n) => n.id === id);
      if (!is_read) {
        setLoadingNotificationId(id);
        markAsRead(id);
      }
    },
    [notifications]
  );

  const activator = (
    <div
      onClick={() => togglePopoverActive()}
      style={{
        position: "fixed",
        width: "35px",
        height: "35px",
        bottom: "100px",
        right: "40px",
        zIndex: 1000,
        lineHeight: 0,
        background: "var(--p-color-bg-surface-inverse)",
        color: "white",
        borderRadius: "150px",
        cursor: "pointer",
      }}
    >
      <Tooltip
        content={t("Show notifications")}
        zIndexOverride={999}
      >
        <span style={{ position: "relative", top: "7px" }}>
          <Icon source={NotificationIcon} />
        </span>
      </Tooltip>
      {notifications?.numOfUnreadNotifications > 0 && (
        <span
          style={{
            width: "8px",
            height: "8px",
            background: "var(--p-color-bg-fill)",
            borderRadius: "var(--p-border-radius-full)",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
        />
      )}
    </div>
  );

  const headerContent = (
    <InlineStack gap="200">
      <Text
        as={"p"}
        variant={"headingMd"}
      >
        {t("Notifications")}
      </Text>
      {notifications?.numOfUnreadNotifications > 0 && <span>({notifications?.numOfUnreadNotifications})</span>}
    </InlineStack>
  );

  const renderItem = ({ id, title, message, is_read, created_at }) => {
    const localNotification = notifications?.list?.notifications?.find((item) => item?.id === id);
    const isRead = localNotification?.is_read ?? is_read;

    return (
      <ResourceItem
        id={id}
        onClick={handleResourceListItemClick}
      >
        <BlockStack gap="200">
          <InlineStack align={"space-between"}>
            <Text
              as="p"
              variant="headingSm"
            >
              {t(title)}
            </Text>
            {loadingNotificationId === id ? (
              <span>
                <Spinner size="small" />
              </span>
            ) : (
              isRead && (
                <span>
                  <Icon
                    source={CheckSmallIcon}
                    tone="base"
                  />
                </span>
              )
            )}
          </InlineStack>
          <Text
            as="p"
            tone="subdued"
          >
            {t(message.slice(0, 40))}...
          </Text>

          <Text
            as="i"
            tone="subdued"
            variant="bodySm"
            alignment="end"
          >
            {formatDate(created_at, "D MMM YYYY h:mm a")}
          </Text>
        </BlockStack>
      </ResourceItem>
    );
  };

  useEffect(() => {
    if (isSuccess && loadingNotificationId !== null) {
      setLoadingNotificationId(null);
    }
  }, [isSuccess, loadingNotificationId]);

  return (
    <Popover
      sectioned
      active={showDropdown}
      activator={activator}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      zIndexOverride={101}
      fixed
    >
      <Popover.Pane>
        <ResourceList
          background="bg-surface"
          items={slicedNotifications}
          renderItem={renderItem}
          showHeader={true}
          headerContent={headerContent}
          alternateTool={
            <Tooltip
              content={t("Read All")}
              zIndexOverride={999}
              preferredPosition={"below"}
            >
              <Button
                onClick={markAllAsReadViaSocket}
                icon={CheckCircleIcon}
                variant="monochromePlain"
                loading={loading}
              />
            </Tooltip>
          }
          // loading={loading}
        />
      </Popover.Pane>

      <Popover.Section>
        <InlineStack align="center">
          <Button
            variant="plain"
            onClick={redirectToNotifications}
          >
            {t("View all notifications")}
          </Button>
        </InlineStack>
      </Popover.Section>
    </Popover>
  );
}
