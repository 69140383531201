import { Bleed, BlockStack, Box, Button, Divider, InlineStack, Text } from "@shopify/polaris";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BANNER_WHATS_NEW } from "storeseo-enums/cacheKeys";
import DismissableBanner from "../common/DismissableBanner";

const WhatsNewCard = () => {
  const { t } = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const whatsNewContents = [
    {
      key: "ai-optimizer",
      content:
        "Try our AI Content Optimizer to make your meta title, meta description, image alt text and tags SEO-friendly with just a single click",
      button: {
        url: "/credit-bundles",
        content: "Check it out",
      },
    },
    {
      key: "image-optimizer",
      content: "Optimize your store images instantly with Image Optimizer & skyrocket your page speed",
      button: {
        url: "/image-optimizer",
        content: "Check it out",
      },
    },
    {
      key: "html-sitemap",
      content:
        "Generate an HTML Sitemap page instantly on your store & help your visitors find all of your pages in one place",
      button: {
        url: "/sitemaps/html-sitemap",
        content: "Check it out",
      },
    },
  ];

  return (
    <DismissableBanner
      title={
        <InlineStack
          gap="200"
          blockAlign="center"
        >
          <Box as="span">🎉</Box> {t("What's New")}
        </InlineStack>
      }
      content={
        <BlockStack gap="200">
          {whatsNewContents.map((item) => (
            <Fragment key={item.key}>
              <Bleed marginInline="400">
                <Divider />
              </Bleed>
              <InlineStack
                gap="200"
                wrap={windowWidth < 499 ? true : false}
                align="space-between"
                blockAlign="center"
              >
                <Text
                  as="p"
                  tone="subdued"
                >
                  {t(item.content)}
                </Text>
                <Box
                  width="150px"
                  align="end"
                >
                  <Button url={item.button.url}>{t(item.button.content)}</Button>
                </Box>
              </InlineStack>
            </Fragment>
          ))}
        </BlockStack>
      }
      bannerKey={BANNER_WHATS_NEW}
    />
  );
};

export default WhatsNewCard;
