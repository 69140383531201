import { ImageIcon } from "@shopify/polaris-icons";
import { isEmpty, isNull, omit, truncate } from "lodash";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import dimensions from "storeseo-enums/analytics/dimensions";
import { METAFIELD_KEYS, NAMESPACE } from "storeseo-enums/metafields";
import { USAGE } from "storeseo-enums/planInterval";
import { FAILED, VALIDATION_ERROR } from "storeseo-enums/responseCodes";
import Confirmation from "../components/common/Confirmation";
import { IS_EXTERNAL_LINK_REGEX, MAX_FILE_SIZE_IN_KB, SORT_DIR } from "../config";

const REG_EXP_FOR_VALID_URL = /^(?:https|http):\/\/(?:www\.)?[\w\W]{2,}\.[a-z]{2,6}/gu;

export const formatDate = (dateTime, formatStr = "DD MMM, YYYY") => moment(dateTime).format(formatStr);

export const formatDateTime = (dateTime) => moment(dateTime).format("DD MMM, YYYY hh:mm:ss A");

export const capitalizedString = (str) =>
  str
    .split(" ")
    .map((s) => s[0].toUpperCase() + s.substring(1))
    .join(" ");

export const getNotificationIcon = (type) => {
  switch (type) {
    case "success":
      return "ss-check-circle";
    case "warning":
      return "ss-warning-circle";
    case "error":
      return "ss-check-circle";
    default:
      return "ss-warning-circle";
  }
};

export const showNotification = ({ message = "", type = "success", timeout = 4500 }) => {
  shopify.toast.hide();
  shopify.toast.show(message, {
    duration: timeout,
    isError: type === "error",
  });
};

export const arrayRemove = (arr) => {
  let what;
  const a = arguments;
  let L = a.length;
  let ax;

  while (L > 1 && arr.length) {
    what = a[--L];
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
};

export const stripTags = (string) => {
  return string
    ?.replace(/(<([^>]+)>)/gi, "")
    .replace(/ +(?= )/g, "")
    .trim();
};

export const sSEOConfirm = ({ title, msg, callback, button }) => {
  const defaultOptions = {
    title: title || "Confirm!",
    msg: msg ?? "Are you sure?",
    callback,
    button: {
      confirm: {
        text: "Yes, Confirm!",
        class: "button__danger",
        ...(button && button.confirm ? button.confirm : undefined),
      },
      cancel: {
        text: "Cancel",
        class: "button__ghostWhite",
        onClick: () => {},
        ...(button && button.cancel ? button.cancel : undefined),
      },
    },
  };

  confirmAlert({
    closeOnClickOutside: true,
    closeOnEscape: true,
    customUI: ({ onClose }) => (
      <Confirmation
        options={defaultOptions}
        closeModal={onClose}
      />
    ),
  });
};

export const textReduce = (string, char, indicator) =>
  truncate(string, {
    length: char,
    separator: /,? +/,
    omission: indicator || "",
  });

export const preventDefault = (e) => e.preventDefault();

export const validateImageFile = (imageFile) => {
  const selectedFileSize = imageFile.size / 1024;
  return selectedFileSize <= MAX_FILE_SIZE_IN_KB;
};

export const validateImageFormat = (imageFile) => {
  if (!imageFile.type.startsWith("image/")) {
    throw Error("Invalid file.");
  }
  return true;
};

// export const appBridgeRedirect = (path) => {
//   const redirect = Redirect.create(window.app);
//   redirect.dispatch(Redirect.Action.REMOTE, path);
// };

// export const historyPush = (path) => {
//   const history = History.create(window.app);
//   history.dispatch(History.Action.PUSH, path);
// };

export const validateURL = (url) => {
  return new RegExp(REG_EXP_FOR_VALID_URL).test(url);
};

export const validateSeoUpdateData = ({
  metaTitle = "",
  metaDescription = "",
  focusKeyword = "",
  tags = [],
  maxAllowedTags = null,
}) => {
  let valid = true;
  const newErrors = {
    title: "",
    description: "",
  };

  if (metaTitle.length === 0) {
    newErrors.title = "Please input valid meta title.";
  }

  if (metaTitle.length > 70) {
    newErrors.title = "Please input meta title within 70 characters.";
  }

  if (metaDescription.length === 0) {
    newErrors.description = "Please input valid meta description.";
  }

  if (metaDescription.length > 165) {
    newErrors.description = "Please input meta description within 165 characters.";
  }

  if (focusKeyword.length === 0) {
    newErrors.focusKeyword = "Please input a focus keyword.";
  }

  if (!isNull(maxAllowedTags)) {
    if (tags.length > maxAllowedTags) {
      newErrors.tags = "Please do not input more than allowed tags.";
    }
  }

  Object.values(newErrors).forEach((val) => val.length > 0 && (valid = false));

  return { valid, errors: newErrors };
};

export const setErrorsFromResponse = async (res, setFormErrors = () => {}) => {
  try {
    const { errors } = await res.json();

    if (!isEmpty(errors)) {
      setFormErrors(errors);
    }
  } catch (e) {}
};

export const processAndShowErrorNotification = async (err, setFormErrors = () => {}) => {
  try {
    const { status } = err;
    const { message, errors } = await err.json();

    if (status === VALIDATION_ERROR && !isEmpty(errors)) {
      setFormErrors(errors);
      return false;
    }

    if (status >= FAILED && message.length > 0) {
      return false;
    }
  } catch (e) {
    // console.log(e);
  }
};

export const processAndReturnError = async (err) => {
  try {
    const { status } = err;
    const { message, errors } = await err.json();

    if (status === VALIDATION_ERROR && !isEmpty(errors)) {
      return Object.values(errors)[0];
    }

    if (status >= FAILED && message.length > 0) {
      return message;
    }
  } catch (e) {
    console.error("Cannot process and return error", e);
  }
};

export const buildUrlSearchQueryString = (params = {}) => {
  const newParams = {};
  for (let p in params) {
    if (params[p]) newParams[p] = params[p];
  }

  const queryString = new URLSearchParams(newParams).toString();

  return queryString ? "?" + queryString : "";
};

/**
 *
 * @param {URLSearchParams} searchParams
 * @returns {{ page: string, limit: string, from: string, to: string, status: string, search: string, filterOn: string, filterValue: string}}
 */
export const getQueryFromUrlSearchParam = (searchParams) => {
  // console.log("searchParams", Object.fromEntries(searchParams.entries()));
  // const { page, limit, from, to, status, search, filterOn, filterValue, sortBy, sortOrder } = Object.fromEntries(
  //   searchParams.entries()
  // );

  let queries = Object.fromEntries(searchParams.entries());

  return omit(queries, ["embedded", "hmac", "host", "locale", "session", "shop", "timestamp", "id_token"]);
};

export const getMetaTitleAndDescription = (item, { useDefaultValue = true } = {}) => {
  if (isEmpty(item)) return { metaTitle: "", metaDescription: "" };

  const metaArr = item.meta || item.metafields;

  let metaTitle = metaArr?.find((m) => m.key === METAFIELD_KEYS.TITLE_TAG)?.value || "";
  let metaDescription = metaArr?.find((m) => m.key === METAFIELD_KEYS.DESCRIPTION_TAG)?.value || "";

  if (useDefaultValue && !metaTitle) {
    metaTitle ||= item.title || "";
  }

  if (useDefaultValue && !metaDescription) {
    metaDescription ||= stripTags(item.description) || stripTags(item.body_html) || "";
    metaDescription = metaDescription.trim().substring(0, 165);
  }

  return {
    metaTitle: metaTitle.trim(),
    metaDescription: metaDescription.trim(),
  };
};

export const getUpdatedMeta = (meta, { title, description }) => {
  const updatedMeta = [...meta];
  let titleFound = false;
  let descriptionFound = false;

  for (let m of updatedMeta) {
    if (m.key === METAFIELD_KEYS.TITLE_TAG) {
      titleFound = true;
      m.value = title;
    } else if (m.key === METAFIELD_KEYS.DESCRIPTION_TAG) {
      descriptionFound = true;
      m.value = description;
    }
  }

  if (!titleFound) updatedMeta.push({ key: METAFIELD_KEYS.TITLE_TAG, value: title });
  if (!descriptionFound) updatedMeta.push({ key: METAFIELD_KEYS.DESCRIPTION_TAG, value: description });

  return updatedMeta;
};

export const getNoIndexNoFollowFromMeta = (metas) => {
  let noIndex = false;
  let noFollow = false;

  for (let m of metas) {
    if (m.namespace === NAMESPACE.STORE_SEO && m.key === METAFIELD_KEYS.NO_INDEX) noIndex = Boolean(Number(m.value));
    else if (m.namespace === NAMESPACE.STORE_SEO && m.key === METAFIELD_KEYS.NO_FOLLOW)
      noFollow = Boolean(Number(m.value));
  }

  return {
    noIndex,
    noFollow,
  };
};

export const ONE_MILLION = 1000000;
export const ONE_THOUSAND = 1000;

export const getReadableStringFormatFromNumber = (num) => {
  num = Number(num);

  if (isNaN(num) || num == null) {
    return "0";
  } else if (num >= ONE_MILLION) {
    const shortedNumber = (num / ONE_MILLION).toFixed(2);
    return `${shortedNumber}M`;
  } else if (num >= ONE_THOUSAND) {
    const shortedNumber = (num / ONE_THOUSAND).toFixed(2);
    return `${shortedNumber}K`;
  } else {
    return Number.isInteger(num) ? num : num.toFixed(2);
  }
};

export const ONE_MINUTE = 60 * 1000;
export const SECONDS_PER_MINUTE = 60;
export const ONE_HOUR = SECONDS_PER_MINUTE * 60;

/**
 *
 * @param {string} startDate
 * @param {string} endDate
 * @param {string} format
 * @returns {string[]}
 */
export const datesBetweenTwoRange = (startDate, endDate, format = "YYYYMMDD") => {
  const dates = [];

  let currentDate = moment(startDate).format(format);
  endDate = moment(endDate).format(format);

  while (currentDate <= endDate) {
    dates.push(currentDate);

    currentDate = moment(currentDate).add(1, "d").format(format);
  }

  return dates;
};

/**
 *
 * @param {import("storeseo-enums/analytics/jsDocTypes").SingleDimensionMetrics} metricData
 * @param {string} label
 * @returns { { series: ApexAxisChartSeries, categories: string[], xaxisType: string }}
 */
export const formatAnalyticsMetricDataForChart = (metricData, label) => {
  if (!metricData) {
    return {
      series: [{ name: label, data: [] }],
      categories: [],
      xaxisType: "datetime",
    };
  }
  const xaxisType = metricData.dimensionName === dimensions.DATE ? "datetime" : "number";

  const { startDate, endDate } = metricData.dateRange;
  const dates = datesBetweenTwoRange(startDate, endDate);

  const seriesKeys = xaxisType === "datetime" ? dates : Object.keys(metricData.values);

  const categories =
    xaxisType === "datetime" ? dates.map((d) => moment(d).format("YYYY-MM-DD")) : Object.keys(metricData.values);

  return {
    series: [{ name: label, data: seriesKeys.map((k) => metricData.values[k] || 0) }],
    categories,
    xaxisType,
  };
};

/**
 *
 * @param {{data: {[string]: string}, label: string, dateRange: import("storeseo-enums/analytics/jsDocTypes").dateRange}} param0
 */
export const serializeDateValueMapForChart = ({ data, dateRange, label }) => {
  const { startDate, endDate } = dateRange;
  const seriesKeys = datesBetweenTwoRange(startDate, endDate, "YYYY-MM-DD");
  const categories = seriesKeys;

  return {
    series: [{ name: label, data: seriesKeys.map((k) => data[k] || 0) }],
    categories,
    xaxisType: "datetime",
  };
};

/**
 *
 * @param {number} seconds
 * @returns {string}
 */
export const convertSecondsToReadableTimeValue = (seconds) => {
  seconds = Number(seconds);

  if (isNaN(seconds) || !seconds) return "0 sec";

  let remainingSeconds = Math.ceil(seconds);

  const hours = Math.floor(remainingSeconds / ONE_HOUR);
  remainingSeconds -= ONE_HOUR * hours;

  const minutes = Math.floor(remainingSeconds / SECONDS_PER_MINUTE);
  remainingSeconds -= SECONDS_PER_MINUTE * minutes;

  let str = "";
  str += hours ? hours + " hr " : "";

  str += minutes ? minutes + " min " : "";
  str += !hours && remainingSeconds ? remainingSeconds + " secs" : "";

  return str;
};

export const pluralizeText = (count, singular, plural) => {
  if (parseInt(count) === 1) {
    return singular;
  } else {
    return plural;
  }
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const prepareThumbnailURL = (url = null) => {
  if (!url) {
    return ImageIcon;
  }

  const extensionMatch = url?.match(/\.([A-Za-z0-9]+)(\?.*)?$/);

  if (extensionMatch) {
    const extension = extensionMatch[1];
    const query = extensionMatch[2] || "";

    // Split the original URL into base URL and filename
    const parts = url.split("/");
    const filename = parts.pop();
    const baseUrl = parts.join("/");
    const dimensions = "30x30@2x";

    // Add the dimensions and extension to the filename
    const newFilename = filename.replace(`.${extension}`, `_${dimensions}.${extension}`);

    // Construct the new URL
    const newUrl = `${baseUrl}/${newFilename}${query}`;

    return newUrl;
  } else {
    // If the URL doesn't contain a file extension, return it unchanged
    return url;
  }
};

export const removeTrailingSlash = (path) => {
  return path.endsWith("/") ? path.slice(0, -1) : path;
};

export const generateTableSL = (pagination, index) => {
  return pagination?.pageCount > 0 ? (pagination.page - 1) * pagination.pageSize + index + 1 : index + 1;
};

export const getIdFromGID = (gid) => {
  const regex = /\/(\d+)$/; // Regular expression to extract the ID from the GID
  const match = gid.match(regex);
  if (match) {
    return match[1];
  } else {
    throw new Error("Invalid GID format");
  }
};
export const scrollToTop = (top = 0) => {
  window.scrollTo({ top, left: 0, behavior: "smooth" });
};

export const isExternalLink = (path) => {
  if (path && IS_EXTERNAL_LINK_REGEX.test(path)) {
    return true;
  }
  return false;
};
export const getPrice = (plan) => {
  return (plan.interval === USAGE ? plan.price : plan.subtotal).toFixed(2);
};

export const hasDiscount = (plan) => {
  return plan.discount > 0 && plan.interval !== USAGE;
};

export const setAllValuesToNull = (obj) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((key) => (newObj[key] = null));
  return newObj;
};

export function hsbaToHex(h, s, b, a = 1) {
  h = ((h % 360) + 360) % 360; // Normalize hue to [0, 360)
  s = Math.max(0, Math.min(100, s)); // Clamp saturation to [0, 100]
  b = Math.max(0, Math.min(100, b)); // Clamp brightness to [0, 100]
  a = Math.max(0, Math.min(1, a)); // Clamp alpha to [0, 1]

  // Convert HSB to RGB
  const c = ((1 - Math.abs(2 * b - 1)) * s) / 100;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = b - c / 2;
  let r, g, blue;

  if (h < 60) {
    [r, g, blue] = [c, x, 0];
  } else if (h < 120) {
    [r, g, blue] = [x, c, 0];
  } else if (h < 180) {
    [r, g, blue] = [0, c, x];
  } else if (h < 240) {
    [r, g, blue] = [0, x, c];
  } else if (h < 300) {
    [r, g, blue] = [x, 0, c];
  } else {
    [r, g, blue] = [c, 0, x];
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  blue = Math.round((blue + m) * 255);

  // Convert RGB to Hex
  const hex = ((1 << 24) + (r << 16) + (g << 8) + blue).toString(16).slice(1);

  return `#${hex}`;
}

export function hexToHSBA(hex) {
  // Check if hex value is a valid hexadecimal color
  if (!/^[0-9a-f]{6}$/i.test(hex)) {
    throw new Error("Invalid hex color value");
  }

  // Convert hex to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Convert RGB to HSL
  let hsl = rgbToHSL(r, g, b);
  let h = hsl[0];
  let s = hsl[1];
  let l = hsl[2];

  // Convert HSL to HSBA
  let a = 1; // Default alpha value is 1 (opaque)
  let hsba = { h, s, l };
  return hsba;
}

export function rgbToHSL(r, g, b) {
  // Convert RGB to fractional values
  r /= 255;
  g /= 255;
  b /= 255;

  // Find max, min, and chroma
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let chroma = max - min;

  // Calculate hue
  let h = 0;
  if (chroma !== 0) {
    if (max === r) {
      h = (g - b) / chroma;
      if (h < 0) {
        h += 6;
      }
    } else if (max === g) {
      h = (b - r) / chroma + 2;
    } else {
      h = (r - g) / chroma + 4;
    }
    h *= 60;
  }

  // Calculate saturation
  let s = 0;
  if (chroma !== 0) {
    s = chroma / (max > 0.5 ? max : 1 - max);
  }

  // Calculate lightness
  let l = (max + min) / 2;

  // Convert hue to degrees
  h = h % 360;

  // Convert saturation and lightness to percentages
  s *= 100;
  l *= 100;

  return [h, s, l];
}

/**
 * Toggle sorting direction between ASC and DESC
 * @param {string} direction - Current sorting direction, either 'ascending' or 'descending'
 * @returns {string}
 */
export const toggleSortDir = (direction) => {
  switch (direction) {
    case SORT_DIR.ASC:
      return SORT_DIR.DESC;
    case SORT_DIR.DESC:
      return SORT_DIR.ASC;
    default:
      return SORT_DIR.DESC;
  }
};

/**
 *
 * @param {string} url shopify image url
 */
export const isSvgImage = (url) => {
  return url?.split(".").reverse()[0].toLowerCase().startsWith("svg");
};

export const getCheckoutPath = (user) => {
  return `/checkout/${user.planSlug}` + (user.planCoupon ? `?coupon=${user.planCoupon}` : "") || null;
};

/**
 * @param {string} value
 * @param {string[]} formats
 * @returns {boolean}
 * @description Check if value is a valid date. By default accepts YYYY-MM-DD, YYYY-M-D, YYYY-MM-D, YYYY-M-DD
 */
export const isValidDate = (value, formats = ["YYYY-MM-DD", "YYYY-M-D", "YYYY-MM-D", "YYYY-M-DD"]) => {
  return formats.some((format) => moment(value, format, true).isValid());
};

/**
 *
 * @param {string} title
 * @param {number} length
 * @returns {string}
 * @description Minify title to a certain length and add ellipsis. Default length is 50
 * @example
 * minifyTitle("This is a long title", 10) // This is a...title
 */
export const minifyTitle = (title, length) => {
  if (title.length <= 50) return title;

  return title.substring(0, length) + "..." + title.substring(title.length - length);
};
