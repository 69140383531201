import useBannerPagination from "@/hooks/useBannerPagination";
import {
  ActionList,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  InlineStack,
  Link,
  Pagination,
  Popover,
  Text,
  Thumbnail,
} from "@shopify/polaris";
import { MenuHorizontalIcon, ReplayIcon, XIcon } from "@shopify/polaris-icons";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BANNER_PARTNERSHIPS } from "storeseo-enums/cacheKeys";
import { useAppQuery, useShopApi } from "../../hooks";
import { setHiddenBanner } from "../../store/features/HiddenBanner";
import { usePartnerApi } from "@/hooks/apiHooks/usePartnerApi";
import queryKeys from "@/utility/queryKeys";

const PartnershipBanner = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shopApi = useShopApi();
  const partnerApi = usePartnerApi();

  const [actionPopoverActive, setActionPopoverActive] = useState(false);
  const hiddenBanner = useSelector((state) => state.hiddenBanner);

  const handleDismiss = async () => {
    dispatch(setHiddenBanner({ bannerKey: BANNER_PARTNERSHIPS, status: false }));
    shopApi.hideBanner(BANNER_PARTNERSHIPS);
  };

  const handleRemindMeLater = async () => {
    dispatch(setHiddenBanner({ bannerKey: BANNER_PARTNERSHIPS, status: false }));
    shopApi.hideBanner(BANNER_PARTNERSHIPS, 7);
  };

  const { data } = useAppQuery({
    queryKey: [queryKeys.PARTNERS_LIST],
    queryFn: () => partnerApi.getPartnersData(),
    reactQueryOptions: {
      staleTime: 0,
    },
  });

  const { pageNumber, handleNext, handlePrev, totalPages } = useBannerPagination(data?.count);

  const partnersList = data?.partners?.slice((pageNumber - 1) * 3, pageNumber * 3);

  const defaultDismissItems = [
    {
      content: t("Remind me later"),
      icon: ReplayIcon,
      onAction: handleRemindMeLater,
    },
    {
      content: t("Dismiss"),
      icon: XIcon,
      destructive: true,
      onAction: handleDismiss,
    },
  ];
  const [dismissItems, setDismissItems] = useState(defaultDismissItems);

  const toggleActionPopover = useCallback(
    () => setActionPopoverActive((actionPopoverActive) => !actionPopoverActive),
    []
  );

  if (!hiddenBanner[BANNER_PARTNERSHIPS]) return null;

  return (
    <Card>
      <BlockStack gap="400">
        <InlineStack
          wrap={false}
          align="space-between"
        >
          <BlockStack gap="100">
            <Text
              variant="headingSm"
              as="h4"
            >
              {t("Discover apps to add additional functionality")}
            </Text>
            <Text
              as="p"
              tone="subdued"
            >
              {t("Take your Shopify experience to the next level with our recommended apps")}
            </Text>
          </BlockStack>

          <Popover
            active={actionPopoverActive}
            activator={
              <Button
                icon={MenuHorizontalIcon}
                variant="tertiary"
                onClick={toggleActionPopover}
              ></Button>
            }
            autofocusTarget="first-node"
            onClose={toggleActionPopover}
            preferredAlignment="left"
          >
            <ActionList
              actionRole="menuitem"
              items={dismissItems}
            />
          </Popover>
        </InlineStack>

        <Bleed marginInline="400">
          <Divider />
        </Bleed>

        <Grid columns={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}>
          {partnersList?.map((partner) => (
            <Grid.Cell key={partner.id}>
              <InlineStack
                gap="400"
                wrap={false}
                blockAlign="start"
              >
                <Box width="40px">
                  <Thumbnail
                    source={partner.logo}
                    alt={partner.name}
                    size="medium"
                  />
                </Box>

                <BlockStack gap="200">
                  <Link
                    url={partner.url}
                    target="_blank"
                    removeUnderline
                  >
                    <Text
                      as="h5"
                      variant="headingSm"
                    >
                      {partner.name}
                    </Text>
                  </Link>
                  <Text
                    as="p"
                    tone="subdued"
                  >
                    {partner.content}
                  </Text>

                  <Link
                    url={partner.url}
                    target="_blank"
                  >
                    {t("View App")}
                  </Link>
                </BlockStack>
              </InlineStack>
            </Grid.Cell>
          ))}
        </Grid>

        <Bleed
          marginInline="400"
          marginBlockEnd="400"
        >
          <Box
            background="bg-surface-secondary"
            padding="100"
            paddingInlineEnd="300"
          >
            <InlineStack align="end">
              <Pagination
                hasPrevious={pageNumber > 1}
                onPrevious={handlePrev}
                hasNext={pageNumber < totalPages}
                onNext={handleNext}
              />
            </InlineStack>
          </Box>
        </Bleed>
      </BlockStack>
    </Card>
  );
};

export default PartnershipBanner;
