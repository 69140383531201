const queryKeys = {
  DASHBOARD_STATS: "DASHBOARD_STATS",
  STATISTICS_REPORT: "STATISTICS_REPORT",

  TOTAL_SHOPIFY_PRODUCTS_COUNT: "TOTAL_SHOPIFY_PRODUCTS_COUNT",
  TOTAL_SHOPIFY_PAGES_COUNT: "TOTAL_SHOPIFY_PAGES_COUNT",
  TOTAL_SHOPIFY_ARTICLES_COUNT: "TOTAL_SHOPIFY_ARTICLES_COUNT",

  PRODUCTS_LIST: "PRODUCTS_LIST",
  PRODUCT_DATA: "PRODUCT_DATA",
  TEMP_PRODUCT_DATA: "TEMP_PRODUCT_DATA",
  PRODUCT_FORM_DATA: "PRODUCT_FORM_DATA",

  NO_INDEX_STATUS: "NO_INDEX_STATUS",
  NO_FOLLOW_STATUS: "NO_FOLLOW_STATUS",
  SITEMAP_STATUS: "SITEMAP_STATUS",

  KEYWORD_METRICS: "KEYWORD_METRICS",

  SITEMAP_LIST: "SITEMAP_LIST",
  SITEMAP_INFO: "SITEMAP_INFO",

  PAGES_LIST: "PAGES_LIST",
  PAGE_DATA: "PAGE_DATA",
  TEMP_PAGE_DATA: "TEMP_PAGE_DATA",
  PAGE_FORM_DATA: "PAGE_FORM_DATA",
  PAGE_SITEMAP_LIST: "PAGE_SITEMAP_LIST",

  ARTICLES_LIST: "ARTICLES_LIST",
  ARTICLE_DATA: "ARTICLE_DATA",
  TEMP_ARTICLE_DATA: "TEMP_ARTICLE_DATA",
  ARTICLE_FORM_DATA: "ARTICLE_FORM_DATA",
  ARTICLE_SITEMAP_LIST: "ARTICLE_SITEMAP_LIST",

  SEO_SETTINGS: "SEO_SETTINGS",
  SHOP_INFO: "SHOP_INFO",
  PAYMENT_HISTORY: "PAYMENT_HISTORY",
  INSTALLED_APPS_LIST: "INSTALLED_APPS_LIST",
  GOOGLE_INTEGRATION_INFO: "GOOGLE_INTEGRATION_INFO",
  LOCAL_SEO_DATA: "LOCAL_SEO_DATA",
  ADDRESS_AUTO_COMPLETE_SUGGESTIONS: "ADDRESS_AUTO_COMPLETE_SUGGESTIONS",
  JSONLD_STATUS: "JSONLD_STATUS",
  GOOGLE_INDEX_STATUS: "GOOGLE_INDEX_STATUS",
  REDIRECT_OUT_OF_STOCK_INFO: "REDIRECT_OUT_OF_STOCK_INFO",
  INDUSTRIES_LIST: "INDUSTRIES_LIST",
  HTML_SITEMAP_SETTING: "HTML_SITEMAP_SETTING",
  HTML_SITEMAP_SETUP_INFO: "HTML_SITEMAP_SETUP_INFO",

  AUTH_USER: "AUTH_USER",

  TOP_PRODUCTS_ANALYTICS: "TOP_PRODUCTS_ANALYTICS",
  ANALYTICS_OVERVIEW_USERS_METRICS: "ANALYTICS_OVERVIEW_USERS_METRICS",
  ANALYTICS_OVERVIEW_SEARCH_METRICS: "ANALYTICS_OVERVIEW_SEARCH_METRICS",
  IMAGES_LIST: "IMAGES_LIST",
  OPTIMIZE_IMAGE: "OPTIMIZE_IMAGE",
  TOP_KEYWORDS_ANALYTICS: "TOP_KEYWORDS_ANALYTICS",
  IMAGE_OPTIMIZE_SETTINGS: "IMAGE_OPTIMIZE_SETTINGS",

  // Collections related query keys
  COLLECTIONS_LIST: "COLLECTIONS_LIST",
  COLLECTION_DATA: "COLLECTION_DATA",
  TOTAL_SHOPIFY_COLLECTIONS_COUNT: "TOTAL_SHOPIFY_COLLECTIONS_COUNT",
  COLLECTIONS_SITEMAP_LIST: "COLLECTIONS_SITEMAP_LIST",

  // partners related query keys
  PARTNERS_LIST: "PARTNERS_LIST",
  
  // Notifications related query keys
  NOTIFICATIONS_LIST: "NOTIFICATIONS_LIST",
};

export default queryKeys;
